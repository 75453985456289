
.mobile-search-container .search-icon {
     box-sizing: border-box;
     padding: 10px;
     width: 42.5px;
     height: 42.5px;
     top:0;
     right:0;
     border-radius: 50%;
     color: #07051a;
     text-align: center;
     font-size: 1.2em;
     transition: all 1s;
     }
 
     .mobile-search-container .search-icon:hover {
          background: #07051a;
          color: white;
          cursor:pointer;
      }

      .mobile-search-container .search-icon.active {
          background: #07051a;
          color: white;
          cursor:pointer;
      }

.mobile-search-container .search-icon-container {
display:flex;
align-items:center;
justify-content: center;
}

.mobile-search {
     position:absolute;
     top: 100%;
     left: 0;
     z-index:100;
     width:100%; 
     transition: all 1s ease;
     background:white;
     border: 4px solid white;
     padding: 5px;
 
     input {
         width:100%;
         height:42.5px;
         line-height: 30px;
         border-radius: 25px;
     outline: 0;
     border: 0;
     font-size: 1em;
     padding: 0 20px;
     }
 }
 
 .mobile-search:hover {
     cursor:pointer;
 }
      
      .mobile-search-container .results-container {
      display:flex;
      flex-direction: column;
      padding:10px;
      width:100%;
      position:absolute;
      border-bottom: 1px solid #eee;
      z-index:100;
      background-color:#fff;
      text-align:center;
      top:calc(100% + 60px);
      left:0;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      .result {
          display:flex;
          flex-direction:row-reverse;
          align-items:center;
          align-self:flex-start;
          box-sizing:content-box;
          padding:10px 50px;
          cursor:pointer;
          border-bottom: 1px solid #eee;
          img {
              width:80px;
              margin-right:10px;
          }
      }
       } 
      
      .results-container .result:last-child {
         border-bottom: none;
      }
     
 