.livrare {
     text-align:center;

     h3 {
          margin-top:20px;
          font-size:20px;
     }
}

table.GeneratedTable {
     width: 100%;
     background-color: #ffffff;
     border-collapse: collapse;
     border-width: 2px;
     border-color: #000000;
     border-style: solid;
     color: #000000;
   }
   
   table.GeneratedTable td, table.GeneratedTable th {
     border-width: 2px;
     border-color: #000000;
     border-style: solid;
     padding: 15px;
   }
   
   table.GeneratedTable thead {
     background-color: #ffffff;
   }
  