.about {
 h1 {
 font-size:4rem;
 color:#CEB7B3;
 text-align:left;
 margin-bottom:1rem;
}

p {
     margin-bottom: 2rem;
}

img {
     width:75%;
     border-right:5px solid #CEB7B3;
}
 }