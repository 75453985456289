.product {
    display:grid;
    grid-template-columns:repeat(2, 1fr)
}

.product-data {
margin:2rem;
margin-top:0;
text-align:left;
h1{
    margin-bottom:1rem;
    text-align:left;
}
.product-desc {
    margin-top:2rem;
}
}

.product-img {
    position:relative;

    img {
        width:100%;
        height:auto;
        object-fit:cover;
        cursor:pointer;
    }
}

.product-img:hover 

.product-desc div {
    margin-top:1rem;
}

.product-desc div:first-child p {
    font-size: 16px;
}

.product-desc > p {
    font-size:16px;
    margin-bottom:0.5rem;
}

.prev-btn, .next-btn {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    z-index:10;
    border:none;
    outline:none;
    background:none;
    cursor:pointer;
}

.prev-btn {
    left:15px;
}
.next-btn {
    right:15px;
}

.product-img-preview-container {
    max-width:100%;
    display:grid;
    grid-gap:5px;
    margin-top:20px;
    justify-content:center;

    
    img:hover {
        opacity: 0.8;
        cursor:pointer;
    }
}

img.product-img-preview {
    object-fit:cover;
    height:auto;
    width:100%;
}



.fabric-type.active {
    background-color: #D9D9D9;
}

.fabric-types {
margin-bottom:2rem;
}

.fabric-type {
    border:none;
    outline:none;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 0.75rem 1.5rem; 
    border-radius:30px;
    font-size:16px;
    cursor:pointer;
    transition: all 0.2s ease;
    margin-top:1rem;
}

.fabric-types-choose {
    margin-top:2rem;
}

.fabric-types div {
    display:flex;
}
.price {
    color:#333;
    margin:.5rem;
    font-size:25px !important;
}

.info-category .schema {
    width:50%;
    margin-top:10px;
}


.structure-category h2, .info-category h2 {
font-size:20px;
}

.structure-category p, .info-category p {
    font-size:14px;
    }

 .structure-category , .info-category  {
    border: 1px solid  rgb(183, 181, 181);
 }

.product-info-btns {
    width:100%;
    display:flex;
}

.product-info-btns button {
    width:50%;
    padding:.75rem 1.5rem;
    font-size:16px; 
    border:none;
    outline:none;
    font-size:16px;
    cursor:pointer;
    transition: all 0.2s ease;
    opacity:0.5;
    border: 1px solid  rgb(183, 181, 181);
}

.info-category, .structure-category {
    padding:1rem 1.5rem;
    margin-top:0 !important;
}

.product-info {
    background-color:#eee;
    // border: 1px solid #333;
}

.product-image {
transition: opacity 0.3s ease-in-out;
}

.product-image.transition {
    opacity:0.5;
}

.price {
    opacity:0;
    transition:all 0.5s ease;
}

.active-price {
    opacity:1;
}

.active-category {
opacity:1 !important;
}

@media only screen and (max-width: 1000px) {
    .product-info-btns {
        width:100%;
        display:flex;
        flex-direction:column;
    }

    .product-info-btns button{
        width:100%;
        text-align:center;
    }
}

@media only screen and (max-width: 870px) {
    .product {
        grid-template-columns:1fr !important;
    }
    .product-data h1 {
        text-align:center;
        margin-top:2rem;
        font-size:2.5rem;
    }

    .fabric-type, .fabric-types p {
        text-align:center;
    }
    
    .fabric-types  {
        margin:2rem 0;
    }

    .fabric-types div{
    display:flex;
    justify-content:center;
    }
    .product-desc {
        text-align: center;
    }
}

.product-info div div {
    margin:1rem 0;
}

.structure-category, .info-category {
    padding-top:0;
}

.fabric-type {
    margin-right:0.5rem;
}

.flex-buttons {
    display:flex;
    flex-direction: column;
}

