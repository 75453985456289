.contact-flex {
     display:flex;
     justify-content: space-between;
}

.contact .contact-info {
     h3 {
          font-size:36px;
          font-family: 'Raleway';
     }
     p {
          font-family: 'Lato'
     }
     .orar {
          margin-top:2rem;
     }
}

@media only screen and(max-width: 1200px) {
     .contact-flex {
          flex-direction:column;
          text-align:center;
     }
     .contact-flex .contact-info {
          margin-bottom:2rem;
     }
}

@media only screen and(max-width: 700px) {
     .contact-flex {
          .contact-info {
               margin-bottom:2rem; 
               h3 {
                    font-size:20px !important;
               } p {
                    font-size:18px !important;
               }
          }
     }
     }
