.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1000;
  display:flex;
  justify-content:center;
  align-items: center;

  .modal {
    width:60%;
    height:60%;
    display:flex;
    align-items:center;
    justify-content: center;
    position:relative;
  }
  img {
    width:80%;
  }
  
  .times-btn {
    position:absolute;
    top:0;
    left:0;
    border:none;
    outline:none;
    padding:1rem; 
    font-size:16px;
    cursor:pointer;
    transform:translateX(50%);
    background-color:#fff;
    color:#333;
    
  }
  
}


