.slider {
    position:relative;

    img {
        width: 100%;
        height:100%;
        object-fit:cover;
    }

    .prev-btn, .next-btn {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        z-index:10;
        border:none;
        outline:none;
        background:none;
        cursor:pointer;
    }
    
    .next-btn {
        right:50px;
    }
    .prev-btn {
        left: 50px;
    } 
    .banner-text {
        position:absolute;
        z-index:10;
        top:10%;
        left:50%;
        transform:translateX(-50%);
        padding:20px;
        h1 {
            color:#fff;
            font-size:60px;
        }
        .info {
            font-weight:400;
        }
    }
    
}

.slide::after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index:1;
}

.slide > * {
    z-index: 100;
}

@media only screen and (max-width: 600px) {
.banner-text  h1{
    font-size:3rem !important;
}
}

@media only screen and (max-width: 500px) {
    .banner-text  h1{
        font-size:2rem !important;
    }
    }