@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;700&display=swap');

*, *::before, *::after {
padding:0;
margin:0;
box-sizing:border-box;
}

:root {
--brown_color: #1F1300;
}

html {
    overflow-x: hidden;
}

body {
    font-family:Arial;
    overflow-x:hidden;
    font-family: 'Lato', sans-serif;
    min-height:100vh !important;
}

li {
    list-style:none;
}

a {
    text-decoration:none;
    color:#000;
}

.container {
    padding: 2rem 4rem;
    max-width:1400px;
    margin: auto;
}

h1 {
    color: var(--brown_color);
    font-family: 'Raleway', sans-serif;
}

.btn {
    border:none;
    outline:none;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 0.75rem 1.5rem; 
    border-radius:30px;
    font-size:16px;
    cursor:pointer;
}

.brown-btn {
    background-color: var(--brown_color);
    color:#fff;
    font-family: 'Lato';
}

@media only screen and (max-width: 600px){
    .container {
        padding:2rem;
    }
}

.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #000 transparent #555 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .pageTitle {
    font-size:64px;
}

@media only screen and (max-width:700px) {
    .pageTitle{
        font-size:40px;
    }
}

/* html, body {
    height: 100%;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  #root {
    flex: 1 0 auto;
  }
  .main-footer {
    flex-shrink: 0;
  } */

  /*Fix footer only on pages where content doesn't ocupy the whole height on larger screens*/
  @media only screen and (min-width: 1920px ) {
        #categories + .footer-wrapper, 
        .contact + .footer-wrapper {
         position:fixed;
         bottom:0;
         left:0;
         width:100%;
    }
    }

  @media only screen and (min-width: 2560px ) {
    .about + .footer-wrapper {
      position:fixed;
         bottom:0;
         left:0;
         width:100%;
    }
  }

  @media only screen and (min-width: 850px) {
    .products-6 + .footer-wrapper {
      position:fixed;
         bottom:0;
         left:0;
         width:100%;
    }
  }
  
  @media only screen and (min-height: 900px) {
    .livrare + .footer-wrapper {
      position:fixed;
         bottom:0;
         left:0;
         width:100%;
    }
  }
  
  @media only screen and (min-width: 2560px ) {
    #product + .footer-wrapper {
      position:fixed;
         bottom:0;
         left:0;
         width:100%;
    }
  }
  #products.container {
    min-height:calc(100vh - 375px) !important;
  }
/* 

  /* .App {
     min-height: 100vh !important;
     
  } */