.products-container {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    justify-content: space-between;
}

#filter {
margin-bottom:1.5rem;
font-family: 'Lato';
padding:0.75rem 1rem;
margin-left:1rem;
}

@media only screen and(max-width:1050px) {
.products-container {
    grid-template-columns: repeat(2, 1fr);
}
}

@media only screen and(max-width:866px) {
    .products-container {
        grid-template-columns: 1fr;
    }
    .filter-container {
        display:flex;
        justify-content:center;
    }
    }
    

