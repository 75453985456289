.gold-detail {
    background-color:#DFD6A7;
    width:80px;
    height:20px;
    margin:2rem auto;
    border-radius:15px;
}

h1 {
    text-align:center;
}
p{
    font-size:20px;
}

.info {
    margin-top:1rem;
    text-align:center;
}
